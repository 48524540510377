import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section class="hero is-large is-danger">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            NOT FOUND
          </h1>
        </div>
      </div>
    </section>

  </Layout>
)

export default NotFoundPage
